export const FormField = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DATE_OF_BIRTH: 'dateOfBirth',
  GENDER: 'gender',
  EMAIL: 'email',
  PHONE: 'phone',
  COUNTRY_CODE: 'countryCode',
  STREET: 'street',
  POSTAL_CODE: 'postalCode',
  CITY: 'city',
  INSURANCE_CARD_NUMBER: 'insuranceCardNumber',
  ID_NUMBER: 'idNumber',
  SWISS_INSURANCE_CARD_NUMBER: 'swissInsuranceCardNumber',
};
