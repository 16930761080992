import { FormField } from 'FormField';
import { CityFragment } from './CityFragment';
import { CountryFragment } from './CountryFragment';
import { DateOfBirthFragment } from './DateOfBirthFragment';
import { EmailFragment } from './EmailFragment';
import { FirstNameFragment } from './FirstNameFragment';
import { GenderFragment } from './GenderFragment';
import { IdNumberFragment } from './IdNumberFragment';
import { InsuranceCardNumberFragment } from './InsuranceCardNumberFragment';
import { LastNameFragment } from './LastNameFragment';
import { PhoneFragment } from './PhoneFragment';
import { PostalCodeFragment } from './PostalCodeFragment';
import { StreetFragment } from './StreetFragment';
import { SwissInsuranceCardNumberFragment } from './SwissInsuranceCardNumberFragment';

export const FormFragmentsMap = {
  [FormField.FIRST_NAME]: FirstNameFragment,
  [FormField.LAST_NAME]: LastNameFragment,
  [FormField.DATE_OF_BIRTH]: DateOfBirthFragment,
  [FormField.GENDER]: GenderFragment,
  [FormField.EMAIL]: EmailFragment,
  [FormField.PHONE]: PhoneFragment,
  [FormField.COUNTRY_CODE]: CountryFragment,
  [FormField.STREET]: StreetFragment,
  [FormField.POSTAL_CODE]: PostalCodeFragment,
  [FormField.CITY]: CityFragment,
  [FormField.INSURANCE_CARD_NUMBER]: InsuranceCardNumberFragment,
  [FormField.SWISS_INSURANCE_CARD_NUMBER]: SwissInsuranceCardNumberFragment,
  [FormField.ID_NUMBER]: IdNumberFragment,
};
