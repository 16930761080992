import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

const CLIENT_ID_QUERY_PARAM = 'clientId';

const useClientId = () => {
  const { search } = useLocation();

  return useMemo(
    () => queryString.parse(search)?.[CLIENT_ID_QUERY_PARAM],
    [search],
  );
};

export default useClientId;
