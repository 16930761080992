import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import EN_COUNTRIES from '../i18n/resources/countries_en.json';

export const useCountries = () => {
  const { locale } = useIntl();
  const [countries, setCountries] = useState(EN_COUNTRIES);

  useEffect(() => {
    import(`../i18n/resources/countries_${locale}.json`).then(
      ({ default: options }) => setCountries(options),
    );
  }, [locale]);

  return countries;
};
