import { useLayoutEffect, useMemo } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Translate } from 'i18n/Translate';
import { Layout } from 'Components/Layout';
import { COMMA_CONCAT, EMPTY_OBJECT, EMPTY_SPACE } from 'AppConstants';

export const Success = ({ data, todayAsDate = new Date() } = EMPTY_OBJECT) => {
  const {
    cfpCode,
    city,
    countryCode,
    dateOfBirth,
    email,
    firstName,
    idNumber,
    insuranceCardNumber,
    lastName,
    phone,
    postalCode,
    street,
    swissInsuranceCardNumber,
  } = data || EMPTY_OBJECT;

  const name = useMemo(
    () => [firstName, lastName].filter(Boolean).join(EMPTY_SPACE),
    [firstName, lastName],
  );
  const address = useMemo(
    () =>
      [countryCode, street, postalCode, city]
        .filter(Boolean)
        .join(COMMA_CONCAT),
    [countryCode, street, postalCode, city],
  );
  const theme = useTheme();
  const isTabletUp = useMediaQuery(theme.breakpoints.up('md'));

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      {/* Headline */}
      <Typography variant="h5" align="left">
        <Box component="span" fontWeight="fontWeightBold">
          <Translate text="register.success" />
        </Box>
      </Typography>

      <Box mt={2} component="section" p={1}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          direction="row"
          justifyContent="center"
        >
          {/* Row 1 */}
          <Grid container item xs={12}>
            <Grid container item xs={12} sm={6} spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <Translate text="register.success.time" component="span" />
                </Typography>
                <Typography variant="body1">
                  <FormattedDate
                    day="numeric"
                    month="long"
                    value={todayAsDate}
                    year="numeric"
                  />
                  {COMMA_CONCAT}
                  <FormattedTime value={todayAsDate} />
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">
                  <Translate text="register.success.pass" component="span" />
                </Typography>
                <Typography variant="body1">{cfpCode}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={6}>
              <Box
                display="flex"
                flexDirection="column"
                ml={isTabletUp ? 'auto' : 0}
                mt={isTabletUp ? 0 : 2}
              >
                <Typography variant="h6">
                  <Translate text="register.success.data" component="span" />
                </Typography>
                <Typography variant="body1">{name}</Typography>
                <Typography variant="body1">
                  <FormattedDate
                    value={dateOfBirth}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                  />
                </Typography>
                {address && <Typography variant="body1">{address}</Typography>}
                {phone && <Typography variant="body1">{phone}</Typography>}
                {email && <Typography variant="body1">{email}</Typography>}
                {insuranceCardNumber && (
                  <Typography variant="body1">{insuranceCardNumber}</Typography>
                )}
                {swissInsuranceCardNumber && (
                  <Typography variant="body1">
                    {swissInsuranceCardNumber}
                  </Typography>
                )}
                {idNumber && (
                  <Typography variant="body1">{idNumber}</Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Box mt={5}>
              <Typography variant="body1">
                <Translate text="register.success.message" component="span" />
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="body1" color="textSecondary">
                <b>
                  <Translate
                    text="register.success.message.spam"
                    component="span"
                  />
                </b>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};
