import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROOT_URL, UNDEFINED } from 'AppConstants';
import { AppProviders } from 'AppProviders';
import { RegistrationRoute } from 'Routes/Registration';

const App = (webComponentAttrs) => {
  return (
    <BrowserRouter>
      <Switch>
        <AppProviders webComponentAttrs={webComponentAttrs}>
          <Route path={ROOT_URL} component={RegistrationRoute} />
        </AppProviders>
      </Switch>
    </BrowserRouter>
  );
};

App.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  client_id: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  first_name: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  last_name: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  date_of_birth: PropTypes.string,
};

App.defaultProps = {
  client_id: UNDEFINED,
  first_name: UNDEFINED,
  last_name: UNDEFINED,
  date_of_birth: UNDEFINED,
};

export default App;
