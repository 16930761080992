import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

const LOCALE_QUERY_PARAM = 'locale';

const useQueryLocale = () => {
  const location = useLocation();

  return useMemo(
    () => queryString.parse(location?.search)?.[LOCALE_QUERY_PARAM],
    [location],
  );
};

export default useQueryLocale;
