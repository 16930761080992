import { get, has, isNil, negate, pickBy } from 'lodash-es';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';

export const RouteParams = {
  EMBEDDED_MODE: 'embedded',
  NO_HEADLINE: 'no-headline',
  REGISTER_BUTTON_TEXT: 'cta-btn-text',
};

const useRuntimeConfig = () => {
  const location = useLocation();
  const search = queryString.parse(location?.search);

  const isEmbedded = useMemo(
    () => has(search, RouteParams.EMBEDDED_MODE),
    [search],
  );
  const isHeadlineVisible = useMemo(
    () => !has(search, RouteParams.NO_HEADLINE),
    [search],
  );
  const registerButtonText = useMemo(
    () => get(search, RouteParams.REGISTER_BUTTON_TEXT),
    [search],
  );

  return pickBy(
    {
      isEmbedded,
      isHeadlineVisible,
      registerButtonText,
    },
    negate(isNil),
  );
};

export default useRuntimeConfig;
