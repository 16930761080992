import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, TextField } from '@mui/material';
import { Translate } from 'i18n/Translate';

export const InsuranceCardNumberFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        control={control}
        name="insuranceCardNumber"
        rules={{
          required: formatMessage({
            id: 'register.input.insuranceCardNumber.required',
          }),
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
            label={<Translate text="register.input.insuranceCardNumber" />}
            margin="dense"
            placeholder={formatMessage({
              id: 'register.input.insuranceCardNumber.placeholder',
            })}
            variant="outlined"
            size="small"
            {...field}
          />
        )}
      />
    </Grid>
  );
};
