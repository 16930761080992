import { useCallback, useMemo, useState } from 'react';
import { UNDEFINED } from 'AppConstants';
import { Notification } from './Notification';
import { NotificationContext } from './NotificationContext';

const SUCCESS_TYPE = 'success';

export const NotificationProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState(UNDEFINED);
  const [type, setType] = useState(SUCCESS_TYPE);

  const showNotification = useCallback((nextType, nextText) => {
    setType(nextType || SUCCESS_TYPE);
    setText(nextText);
    setShow(true);
  }, []);
  const value = useMemo(() => ({ showNotification }), [showNotification]);

  return (
    <>
      <NotificationContext.Provider value={value}>
        {children}
      </NotificationContext.Provider>
      <Notification isOpen={show} setIsOpen={setShow} text={text} type={type} />
    </>
  );
};
