import { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  chunk,
  identity,
  isEmpty,
  noop,
  pick,
  pickBy,
  replace,
} from 'lodash-es';
import { Controller, useForm } from 'react-hook-form';
import * as queryString from 'query-string';
import { FormattedMessage, useIntl } from 'react-intl';
import parsePhoneNumber from 'libphonenumber-js';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import format from 'date-fns/format';
import { isDate } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import {
  API_DATE_FORMAT,
  EMPTY_OBJECT,
  EMPTY_STRING,
  SEARCH_QUERY,
} from 'AppConstants';
import { Translate } from 'i18n/Translate';
import useSubmitForm from 'hooks/useSubmitForm';
import { Layout } from 'Components/Layout';
import { FormField } from 'FormField';
import { NotificationContext } from 'Components/Notifications/NotificationContext';
import { ERROR_NOTIFICATION } from 'Components/Notifications/NotificationType';
import { Details } from './Details';
import { FormFragmentsMap } from './Fragments';

const DEFAULT_VALUES = {
  [FormField.CITY]: EMPTY_STRING,
  [FormField.COUNTRY_CODE]: EMPTY_STRING,
  [FormField.DATE_OF_BIRTH]: null,
  [FormField.EMAIL]: EMPTY_STRING,
  [FormField.FIRST_NAME]: EMPTY_STRING,
  [FormField.GENDER]: EMPTY_STRING,
  [FormField.ID_NUMBER]: EMPTY_STRING,
  [FormField.INSURANCE_CARD_NUMBER]: EMPTY_STRING,
  [FormField.LAST_NAME]: EMPTY_STRING,
  [FormField.PHONE]: EMPTY_STRING,
  [FormField.POSTAL_CODE]: EMPTY_STRING,
  [FormField.STREET]: EMPTY_STRING,
  [FormField.SWISS_INSURANCE_CARD_NUMBER]: EMPTY_STRING,
  acknowledgedTermsAndPrivacy: false,
};

/**
 * The number of grid form rows
 */
const MAX_FORM_FIELDS_PER_ROW = 2;

export const Registration = ({
  onSuccess = noop,
  configuration,
  prefilledFormValues,
}) => {
  const { showNotification } = useContext(NotificationContext);
  const { formatMessage, locale } = useIntl();
  const theme = useTheme();
  const isTabletUp = useMediaQuery(theme.breakpoints.up('md'));

  const configurableFields = useMemo(
    () => configuration?.formFields || Object.values(FormField),
    [configuration],
  );

  const fieldsSet = useMemo(
    () => chunk(configurableFields, MAX_FORM_FIELDS_PER_ROW),
    [configurableFields],
  );

  const isEmptyFieldSet = useMemo(() => isEmpty(fieldsSet), [fieldsSet]);

  const defaultValues = useMemo(
    () =>
      pick(
        {
          ...DEFAULT_VALUES,
          ...prefilledFormValues,
          [FormField.COUNTRY_CODE]:
            configuration?.defaultCountryCode ||
            DEFAULT_VALUES[FormField.COUNTRY_CODE],
        },
        configurableFields,
      ),
    [
      configurableFields,
      prefilledFormValues,
      configuration?.defaultCountryCode,
    ],
  );

  const { handleSubmit, control, reset, getValues } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const syncWithDefaultValues = useCallback(
    () =>
      reset(defaultValues, {
        keepErrors: false,
        keepDirty: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      }),
    [defaultValues, reset],
  );

  const resetForm = useCallback(
    (data) => {
      onSuccess({ ...getValues(), ...data });

      syncWithDefaultValues();
    },
    [syncWithDefaultValues, getValues, onSuccess],
  );

  const { mutate, isLoading: isPending } = useSubmitForm({
    onSuccess: resetForm,
  });

  const onSubmit = useCallback(
    async ({
      acknowledgedTermsAndPrivacy: _,
      dateOfBirth,
      phone,
      countryCode,
      swissInsuranceCardNumber,
      ...formValues
    }) => {
      const normalizedDateOfBirth = dateOfBirth
        ? format(
            isDate(dateOfBirth) ? dateOfBirth : parseISO(dateOfBirth),
            API_DATE_FORMAT,
          )
        : EMPTY_STRING;

      const normalizedPhoneNumber = phone
        ? parsePhoneNumber(
            phone,
            countryCode || configuration?.defaultCountryCode,
          )?.formatInternational()
        : EMPTY_STRING;

      const normalizedSwissInsuranceCardNumber = swissInsuranceCardNumber
        ? replace(swissInsuranceCardNumber, / /g, EMPTY_STRING)
        : EMPTY_STRING;

      const payload = pickBy(
        {
          ...formValues,
          countryCode,
          dateOfBirth: normalizedDateOfBirth,
          language: locale,
          phone: normalizedPhoneNumber,
          ...(normalizedSwissInsuranceCardNumber
            ? { insuranceCardNumber: normalizedSwissInsuranceCardNumber }
            : EMPTY_OBJECT),
        },
        identity,
      );

      if (configuration?.targetLink) {
        window.location.assign(
          `${configuration?.targetLink}${SEARCH_QUERY}${queryString.stringify(
            payload,
          )}`,
        );
      } else {
        await mutate(payload);
      }
    },
    [configuration, mutate, locale],
  );

  useEffect(() => {
    if (!isEmptyFieldSet) {
      return;
    }
    showNotification(ERROR_NOTIFICATION, 'register.status.error');
  }, [showNotification, isEmptyFieldSet]);

  const termsAndPrivacyFragment = (
    <Box position="relative" ml={2}>
      <FormControlLabel
        disabled={isPending || isEmptyFieldSet}
        label={
          <Typography variant="body2">
            <FormattedMessage
              id="register.input.termsAndPrivacy"
              values={{
                termsLink: (
                  <Link
                    href={configuration?.gtcUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="hover"
                  >
                    {formatMessage({
                      id: 'register.input.termsAndPrivacy.terms.text',
                    })}
                  </Link>
                ),
                privacyLink: (
                  <Link
                    href={configuration?.privacyPolicyUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="hover"
                  >
                    {formatMessage({
                      id: 'register.input.termsAndPrivacy.privacy.text',
                    })}
                  </Link>
                ),
              }}
            />
          </Typography>
        }
        control={
          <Controller
            rules={{
              required: formatMessage({
                id: 'register.input.termsAndPrivacy.required',
              }),
            }}
            control={control}
            name="acknowledgedTermsAndPrivacy"
            render={({
              field,
              field: { value = false },
              fieldState: { error },
            }) => (
              <>
                <Checkbox
                  color="primary"
                  disabled={isPending || isEmptyFieldSet}
                  checked={value}
                  {...field}
                />

                {error && (
                  <Box
                    position="absolute"
                    left={18}
                    bottom={isTabletUp ? -6 : -12}
                  >
                    <FormHelperText variant="outlined" error>
                      {error?.message}
                    </FormHelperText>
                  </Box>
                )}
              </>
            )}
          />
        }
      />
    </Box>
  );

  const submitButtonFragment = (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        pt={6}
        mr="auto"
        ml="auto"
        maxWidth={isTabletUp ? 260 : 1}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isPending || isEmptyFieldSet}
        >
          {configuration?.runtimeConfig?.registerButtonText ?? (
            <Translate text="register.button" />
          )}
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Layout>
      {configuration?.runtimeConfig?.isHeadlineVisible && (
        <>
          {/* Headline */}
          <Typography variant="h5" align="left">
            <Box component="span" fontWeight="fontWeightBold">
              <Translate text="register.title" />
            </Box>
          </Typography>

          {/* Details of the CFP */}
          <Details />
        </>
      )}

      {/* CFP form */}
      <Box mt={2} p={1} component="section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            {fieldsSet.map(([fieldRowLeft, fieldRowRight]) => {
              const LeftFragment = FormFragmentsMap[fieldRowLeft];
              const RightFragment = FormFragmentsMap[fieldRowRight];

              return (
                <Grid
                  container
                  item
                  key={`${fieldRowLeft}_${fieldRowRight}`}
                  spacing={3}
                  xs={12}
                >
                  {LeftFragment && (
                    <LeftFragment
                      control={control}
                      isDisabled={isPending}
                      configuration={configuration}
                    />
                  )}
                  {RightFragment && (
                    <RightFragment
                      control={control}
                      isDisabled={isPending}
                      configuration={configuration}
                    />
                  )}
                </Grid>
              );
            })}

            <Grid container item xs={12} spacing={3}>
              <Grid item>
                <Typography variant="subtitle1">
                  <Box mt={1} fontWeight="fontWeightBold">
                    <Translate text="register.form.section" component="span" />
                  </Box>
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {/* Checkbox t&c & privacy */}
              {termsAndPrivacyFragment}
            </Grid>

            {submitButtonFragment}
          </Grid>
        </form>
      </Box>
    </Layout>
  );
};
