import { isEmpty, isNil, negate, pick, pickBy } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import { useMemo } from 'react';
import { FormField } from 'FormField';
import { EMPTY_OBJECT } from 'AppConstants';
import { snakeToCamelCase } from 'utils';

const usePrefilledDefaultValues = (webComponentFormValueAttributes) => {
  const location = useLocation();
  const locationSearch = queryString.parse(location?.search);

  const formValuesFromWCAttrs = useMemo(
    () =>
      pick(
        pickBy(
          snakeToCamelCase(webComponentFormValueAttributes),
          negate(isNil),
        ),
        [FormField.FIRST_NAME, FormField.LAST_NAME, FormField.DATE_OF_BIRTH],
      ),
    [webComponentFormValueAttributes],
  );

  const resolvedDefaultParams = pick(
    isEmpty(formValuesFromWCAttrs) ? locationSearch : formValuesFromWCAttrs,
    [FormField.FIRST_NAME, FormField.LAST_NAME, FormField.DATE_OF_BIRTH],
  );

  const maybeDateOfBirth = resolvedDefaultParams?.[FormField.DATE_OF_BIRTH];
  const maybeNormalizedParameters = maybeDateOfBirth
    ? {
        [FormField.DATE_OF_BIRTH]: new Date(
          resolvedDefaultParams?.[FormField.DATE_OF_BIRTH],
        ),
      }
    : EMPTY_OBJECT;

  return {
    ...resolvedDefaultParams,
    ...maybeNormalizedParameters,
  };
};

export default usePrefilledDefaultValues;
