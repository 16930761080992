import { createContext, useMemo } from 'react';
import useFetchConfig from 'hooks/useFetchConfig';
import useRuntimeConfig from 'hooks/useRuntimeConfig';

export const ConfigurationContext = createContext({});
ConfigurationContext.displayName = 'Configuration';

export const ConfigurationProvider = ({
  children,
  prefilledFormValues,
  clientId,
}) => {
  const { isLoading, data: configuration } = useFetchConfig(clientId);
  const runtimeConfig = useRuntimeConfig();

  const contextValue = useMemo(
    () => ({
      configuration: {
        ...configuration,
        runtimeConfig,
        clientId,
      },
      prefilledFormValues,
      isLoading,
    }),
    [configuration, runtimeConfig, clientId, prefilledFormValues, isLoading],
  );

  return (
    <ConfigurationContext.Provider value={contextValue}>
      {children}
    </ConfigurationContext.Provider>
  );
};
