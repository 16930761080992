import { useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import phoneNumberExamples from 'libphonenumber-js/examples.mobile.json';
import { useIntl } from 'react-intl';
import { getExampleNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { Grid, TextField } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { FormField } from 'FormField';

export const PhoneFragment = ({ control, isDisabled, configuration }) => {
  const { formatMessage } = useIntl();
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const countryCode = useWatch({
    control,
    name: FormField.COUNTRY_CODE,
    defaultValue: configuration?.defaultCountryCode,
  });
  const phone = useWatch({
    control,
    name: FormField.PHONE,
  });

  const phoneNumberExample = useMemo(
    () =>
      getExampleNumber(countryCode, phoneNumberExamples)?.formatInternational(),
    [countryCode],
  );

  const placeholder = useMemo(
    () =>
      formatMessage(
        {
          id: 'register.input.phone.placeholder',
        },
        { phoneNumber: phoneNumberExample },
      ),
    [formatMessage, phoneNumberExample],
  );

  const errorMessage = useMemo(
    () =>
      formatMessage(
        {
          id: 'register.input.phone.invalid',
        },
        { phoneNumber: phoneNumberExample },
      ),
    [formatMessage, phoneNumberExample],
  );

  useEffect(() => {
    if (!phone) {
      return;
    }

    if (isValidPhoneNumber(phone, countryCode)) {
      clearErrors(FormField.PHONE);

      return;
    }

    setError(FormField.PHONE, {
      type: 'manual',
      message: errorMessage,
    });
  }, [clearErrors, setError, errorMessage, phone, countryCode]);

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        control={control}
        name={FormField.PHONE}
        rules={{
          required: formatMessage({
            id: 'register.input.phone.required',
          }),
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error || !!errors.phone}
            fullWidth
            helperText={error?.message || errors?.phone?.message}
            InputLabelProps={{ shrink: true }}
            label={<Translate text="register.input.phone" />}
            margin="dense"
            placeholder={placeholder}
            variant="outlined"
            size="small"
            {...field}
          />
        )}
      />
    </Grid>
  );
};
