import { useMemo } from 'react';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import StylesProvider from '@mui/styles/StylesProvider';
import { CssBaseline } from '@mui/material';
import { AppColors } from 'AppColors';
import { GlobalStyle } from 'GlobalStyle';
import useConfig from 'hooks/useConfig';
import useRuntimeConfig from 'hooks/useRuntimeConfig';

const DEFAULT_THEME_COLORS = {
  text: AppColors.text,
  red: AppColors.red,
};

export const ThemeProvider = ({ children }) => {
  const { configuration } = useConfig();
  const { isEmbedded: hideBackground } = useRuntimeConfig();
  const main = useMemo(
    () => configuration?.mainColor || AppColors.mainLightest,
    [configuration],
  );

  return (
    <StylesProvider injectFirst>
      <ScThemeProvider
        theme={{
          ...DEFAULT_THEME_COLORS,
          main,
        }}
      >
        <GlobalStyle hideBackground={hideBackground} />
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider
            theme={createTheme({
              typography: {
                button: {
                  textTransform: 'none',
                },
                allVariants: {
                  color: AppColors.text,
                },
                h5: {
                  color: AppColors.black,
                },
                h6: {
                  color: AppColors.black,
                  fontSize: '1rem',
                },
                subtitle1: {
                  color: AppColors.black,
                },
              },
              components: {
                MuiCssBaseline: {
                  styleOverrides: {
                    body: {
                      backgroundColor: hideBackground
                        ? 'transparent'
                        : AppColors.background,
                    },
                  },
                },
                MuiSelect: {
                  styleOverrides: {
                    root: {
                      color: AppColors.text,
                    },
                  },
                },
              },
              palette: {
                text: { hint: 'rgba(0, 0, 0, 0.38)' },
                mode: 'light',
                type: 'light',
                primary: {
                  main,
                },
                secondary: {
                  main,
                },
              },
            })}
          >
            <CssBaseline />
            {children}
          </MuiThemeProvider>
        </StyledEngineProvider>
      </ScThemeProvider>
    </StylesProvider>
  );
};
