import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, TextField } from '@mui/material';
import { isNaN } from 'lodash-es';
import { DatePicker } from '@mui/x-date-pickers';
import { Translate } from 'i18n/Translate';

const minValidateDate = '1900-01-01';
const maxValidateDate = `${new Date().getFullYear() - 1}-12-31`;

export const DateOfBirthFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();

  const checkDate = (dateOfBirth) =>
    !isNaN(dateOfBirth) &&
    dateOfBirth > new Date(minValidateDate).getTime() &&
    dateOfBirth < new Date(maxValidateDate).getTime();

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        control={control}
        name="dateOfBirth"
        defaultValue={null}
        rules={{
          required: formatMessage({
            id: 'register.input.dateOfBirth.required',
          }),
          validate: (value) =>
            checkDate(value?.getTime?.()) ||
            formatMessage({
              id: 'register.input.dateOfBirth.incorrect',
            }),
        }}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            disableCloseOnSelect
            readOnly={isDisabled}
            mask="__.__.____"
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                fullWidth
                name="dateOfBirth"
                id="dateOfBirth"
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
                label={<Translate text="register.input.dateOfBirth" />}
                margin="dense"
                variant="outlined"
                placeholder={formatMessage({
                  id: 'register.input.dateOfBirth.placeholder',
                })}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'tt.mm.jjjj',
                }}
                size="small"
              />
            )}
            {...field}
          />
        )}
      />
    </Grid>
  );
};
