import { useMemo, useEffect, useState } from 'react';
import { first } from 'lodash-es';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { Language } from 'Language';
import useQueryLocale from 'hooks/useQueryLocale';
import ENLocale from './locales/en.json';

export const IntlProvider = ({ children, navigatorLanguage }) => {
  const [messages, setMessages] = useState(ENLocale);
  const queryLocale = useQueryLocale();

  const language = useMemo(() => {
    const browserLanguage =
      queryLocale ||
      navigatorLanguage ||
      first(navigator?.language?.split(/[-_]/));
    return Object.values(Language).includes(browserLanguage)
      ? browserLanguage
      : Language.EN;
  }, [queryLocale, navigatorLanguage]);

  useEffect(() => {
    if (!Object.values(Language).includes(language)) {
      return;
    }

    import(`./locales/${language}.json`).then((data) =>
      setMessages(data.default),
    );
  }, [language]);

  return (
    <ReactIntlProvider
      key={language}
      defaultLocale={Language.EN}
      locale={language}
      messages={messages}
    >
      {children}
    </ReactIntlProvider>
  );
};
