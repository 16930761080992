import { Backdrop, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { AppColors } from 'AppColors';
import useConfig from 'hooks/useConfig';

const PREFIX = 'BackdropSpinner';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
  progress: `${PREFIX}-progress`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: theme?.zIndex?.drawer || DEFAULT_DRAWER_VALUE + 1,
  },

  [`& .${classes.progress}`]: {
    color: AppColors.black,
  },
}));

const DEFAULT_DRAWER_VALUE = 999;

export const BackdropSpinner = ({ isOpen }) => {
  const { isLoading } = useConfig();

  return (
    <StyledBox>
      <Backdrop
        className={classes.backdrop}
        data-testid="@keyper-backdrop"
        open={isOpen}
      >
        <CircularProgress
          className={clsx({
            [classes.progress]: isLoading,
          })}
        />
      </Backdrop>
    </StyledBox>
  );
};
