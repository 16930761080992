import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, MenuItem, TextField } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { useCountries } from 'hooks/useCountries';

export const CountryFragment = ({ control, isDisabled }) => {
  const { formatMessage } = useIntl();
  const countryCodeOptions = useCountries();

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        control={control}
        name="countryCode"
        rules={{
          required: formatMessage({
            id: 'register.input.countryCode.required',
          }),
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            label={<Translate text="register.input.countryCode" />}
            margin="dense"
            select
            variant="outlined"
            size="small"
            {...field}
          >
            {countryCodeOptions.map(({ name, code }) => (
              <MenuItem key={name} value={code}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Grid>
  );
};
