import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid, MenuItem, TextField } from '@mui/material';
import { Translate } from 'i18n/Translate';
import { EMPTY_ARRAY } from 'AppConstants';

export const GenderFragment = ({ control, isDisabled }) => {
  const { formatMessage, locale } = useIntl();

  // ---
  // Load gender options async.
  const [genderOptions, setGenderOptions] = useState(EMPTY_ARRAY);

  useEffect(() => {
    import(`../../../i18n/resources/genders_${locale}.json`).then(
      ({ default: options }) => setGenderOptions(options),
    );
  }, [locale]);

  return (
    <Grid item xs={12} sm={6}>
      <Controller
        control={control}
        name="gender"
        rules={{
          required: formatMessage({
            id: 'register.input.gender.required',
          }),
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            label={<Translate text="register.input.gender" />}
            margin="dense"
            select
            variant="outlined"
            size="small"
            {...field}
          >
            {genderOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Grid>
  );
};
