import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EMPTY_STRING, SKIP_RENDER } from 'AppConstants';
import { AppColors } from 'AppColors';
import { Translate } from 'i18n/Translate';
import useRuntimeConfig from 'hooks/useRuntimeConfig';
import { ReactComponent as CFPIcon } from './CFPIcon.svg';
import BaselIcon from './BaselIcon.png';

const PREFIX = 'Header';

const classes = {
  header: `${PREFIX}-header`,
  partner: `${PREFIX}-partner`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.header}`]: {
    borderBottom: `1px solid ${AppColors.text}`,
    height: theme.spacing(9),
  },

  [`& .${classes.partner}`]: {
    height: theme.spacing(5),
    minWidth: theme.spacing(25),
    width: theme.spacing(18),
  },
}));

export const Header = () => {
  const theme = useTheme();
  const isTabletUp = useMediaQuery(theme.breakpoints.up('md'));
  const { isEmbedded: shouldBeHidden } = useRuntimeConfig();

  return shouldBeHidden ? (
    SKIP_RENDER
  ) : (
    <StyledBox
      className={classes.header}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      pl={isTabletUp ? 20 : 1}
      pr={isTabletUp ? 20 : 1}
    >
      <Box display="flex" justifyContent="center" width={200}>
        <CFPIcon />
      </Box>
      <Box display="flex" justifyContent="flex-right" alignItems="center">
        {isTabletUp ? (
          <>
            <Typography variant="body1">
              <Box
                display="flex"
                mr={1}
                fontWeight="fontWeightBold"
                color={AppColors.black}
                component="span"
              >
                <Translate text="header" />
              </Box>
            </Typography>
            <Box display="flex" className={classes.partner}>
              <img src={BaselIcon} alt={EMPTY_STRING} />
            </Box>
          </>
        ) : (
          SKIP_RENDER
        )}
      </Box>
    </StyledBox>
  );
};
