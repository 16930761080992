import { QueryClient, QueryClientProvider } from 'react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deATLocale from 'date-fns/locale/de-AT';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useMemo } from 'react';
import { NotificationProvider } from 'Components/Notifications/NotificationProvider';
import { ThemeProvider } from 'ThemeProvider';
import { IntlProvider } from 'i18n/IntlProvider';
import { ConfigurationProvider } from 'ConfigurationProvider';
import usePrefilledDefaultValues from 'hooks/usePrefilledDefaultValues';
import { snakeToCamelCase } from 'utils';
import useClientId from 'hooks/useClientId';

const DEFAULT_QUERY_CLIENT_OPTIONS = {
  defaultOptions: {
    staleTime: Number.MAX_SAFE_INTEGER,
    queries: {
      retry: 0,
    },
  },
};

export const AppProviders = ({ children, webComponentAttrs }) => {
  const { clientId: maybeClientIdFromWebComponentAttrs, ...normalizedProps } =
    useMemo(() => snakeToCamelCase(webComponentAttrs), [webComponentAttrs]);
  const clientIdFromParams = useClientId();
  const prefilledFormValues = usePrefilledDefaultValues(normalizedProps);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={deATLocale}
    >
      <QueryClientProvider
        client={new QueryClient(DEFAULT_QUERY_CLIENT_OPTIONS)}
      >
        <ConfigurationProvider
          prefilledFormValues={prefilledFormValues}
          clientId={maybeClientIdFromWebComponentAttrs || clientIdFromParams}
        >
          <ThemeProvider>
            <IntlProvider>
              <NotificationProvider>{children}</NotificationProvider>
            </IntlProvider>
          </ThemeProvider>
        </ConfigurationProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};
