import { useCallback } from 'react';
import { identity, pickBy } from 'lodash-es';
import { UNDEFINED } from 'AppConstants';

const CONTENT_TYPE_APPLICATION_JSON = 'application/json';
const API_VERSION = '2021-03-31';
const APP_VERSION = `web ${process.env.REACT_APP_VERSION}`;

const CLIENT_ID_HEADER_KEY = 'X-Client-ID';

const DEFAULT_HEADERS = {
  'Content-Type': CONTENT_TYPE_APPLICATION_JSON,
  'Api-Version': API_VERSION,
  'App-Version': APP_VERSION,
};

const getBaseUrl = () => {
  const env = process.env.REACT_APP_KEYPER_ENV?.toLowerCase();

  switch (env) {
    case 'develop':
      return 'https://api.dev.c19t.net/v1/cfp';
    case 'staging':
      return 'https://api.sta.c19t.net/v1/cfp';
    case 'sandbox':
      return 'https://api.sbx.c19t.net/v1/cfp';
    case 'production':
      return 'https://api.c19t.net/v1/cfp';
    default:
      throw new Error('The API is not configured');
  }
};

export const useApi = (clientId) => {
  return {
    post: useCallback(
      async (body) => {
        const response = await fetch(`${getBaseUrl()}`, {
          method: 'POST',
          headers: pickBy(
            {
              ...DEFAULT_HEADERS,
              [CLIENT_ID_HEADER_KEY]: clientId,
            },
            identity,
          ),
          body: JSON.stringify(body),
        });
        if (!response.ok) {
          throw new Error('Request failed');
        }

        return response.json();
      },
      [clientId],
    ),

    get: useCallback(
      async (url) => {
        const response = await fetch(`${getBaseUrl()}/${url}`, {
          method: 'GET',
          headers: pickBy(
            {
              ...DEFAULT_HEADERS,
              [CLIENT_ID_HEADER_KEY]: clientId,
            },
            identity,
          ),
        });
        if (response.ok) {
          return response.json();
        }
        return UNDEFINED;
      },
      [clientId],
    ),
  };
};
