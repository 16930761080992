import { useState } from 'react';
import { UNDEFINED } from 'AppConstants';
import useConfig from 'hooks/useConfig';
import { BackdropSpinner } from 'Components/BackdropSpinner';
import { Registration } from './Registration';
import { Success } from './Success';

export const RegistrationRoute = () => {
  const [submittedData, setSubmittedData] = useState(UNDEFINED);
  const { configuration, isLoading, prefilledFormValues } = useConfig();

  if (isLoading) {
    return <BackdropSpinner isOpen />;
  }

  if (submittedData) {
    return <Success data={submittedData} />;
  }

  return (
    <Registration
      onSuccess={setSubmittedData}
      configuration={configuration}
      prefilledFormValues={prefilledFormValues}
    />
  );
};
