import { Info } from '@mui/icons-material';
import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { AppColors } from 'AppColors';
import { Translate } from 'i18n/Translate';
import { ReactComponent as ShieldIcon } from './shield.svg';

export const Details = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const isTabletUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box p={3} pt={2} mt={2} bgcolor={AppColors.mainLightest}>
      <Box alignItems="center" display="flex" justifyContent="start">
        <Box pr={1}>
          <Info color="primary" />
        </Box>
        <Typography variant="h6" color="primary">
          <Translate text="cfp.about" />
        </Typography>
      </Box>

      <Box
        mt={2}
        alignItems="center"
        display="flex"
        justifyContent="start"
        flexDirection={isTabletUp ? 'row' : 'column'}
      >
        <Box pr={1}>
          <Typography variant="body2">
            <Translate text="cfp.details" />
          </Typography>
        </Box>
        <Box maxHeight={150} height={1} display="flex" width={1}>
          <ShieldIcon />
        </Box>
      </Box>

      <Box mt={3}>
        <Typography variant="body2">
          <Translate text="cfp.more.infos" />
          <Box ml={1} component="span" color="primary">
            <Link
              href={formatMessage({ id: 'cfp.link' })}
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
            >
              <Translate text="cfp.link" />
            </Link>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};
