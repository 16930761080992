import { Box, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Header } from 'Components/Header';
import { Footer } from 'Components/Footer';

const PREFIX = 'Layout';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    minHeight: `calc(100vh - ${theme.spacing(20)})`,
  },
}));

export const Layout = ({ children }) => {
  const theme = useTheme();

  const isTabletUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Root>
      <Header />
      <Box w={1} p={2}>
        <Box
          display="flex"
          flexDirection="column"
          ml="auto"
          mr="auto"
          mt={3}
          width={isTabletUp ? theme.spacing(81) : 1}
        >
          <Box className={classes.root}>{children}</Box>
          <Footer />
        </Box>
      </Box>
    </Root>
  );
};
